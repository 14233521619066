import React from "react";

export const MyTextInput = ({
  label,
  value,
  onChange,
  required,
  numeric,
  rightLabel,
  date,
  error,
  disabled,
}) => {
  const onValueChange = (e) => {
    let newValue = e.target.value;
    onChange(newValue);
  };
  return (
    <div className="flex flex-col mx-5 mb-5">
      <div className="flex flex-row">
        <div className="font-sans  text-md text-lime-800 ">{label}</div>
        <div className="font-sans  text-md text-red-500 ">
          {required ? "*" : ""}
        </div>
        <div className=" flex-1 font-sans  text-sm text-lime-800  text-right">
          {rightLabel}
        </div>
      </div>
      <input
        disabled={disabled}
        type={numeric?"number":date?"date":"text"}
        value={value}
        onChange={onValueChange}
        className="rounded-lg  outline-none border-2 border-lime-500 focus:border-lime-600 hover:border-lime-600 p-2 text-lime-800 min-w-full"
      />
      <div className="font-sans  text-md text-red-500 text-xs">{error}</div>
    </div>
  );
};
