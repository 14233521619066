import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    date: null,
    INR_to_USD:null
};
  
export const exchangeRateSlice = createSlice({
    name: "exchangeRates",
    initialState: initialState,
    reducers: {
      // Actions to set the exchangeRates
      setExchangeRates(state, { payload }) {
            state = { ...payload };
        return state;
      },
      clearExchangeRates() {
        return initialState;
      },
    },
});
  
export const {setExchangeRates,clearExchangeRates} = exchangeRateSlice.actions