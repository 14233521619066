import React, { useState } from "react";

export const MyDropdown = ({ uniqueId }) => {
  const [isOpen, setOpen] = useState(false);

  const handleDropDown = () => {
    setOpen(!isOpen);
  };
  return (
    <div>
      <button
        id="dropdownMenuIconButton"
        data-dropdown-toggle={`dropdownDots${uniqueId}`}
        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-gray-200 rounded-full hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
        type="button"
        onClick={handleDropDown}
      >
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 4 15"
        >
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
        </svg>
      </button>

      <div
        id={`dropdownDots${uniqueId}`}
        className={`z-10000 absolute ${isOpen ? "block" : "hidden"} bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
      >
        <ul
          className="py-2 text-sm text-gray-700 "
          aria-labelledby="dropdownMenuIconButton"
        >
          <li>
            <a
              href="#"
              className="block px-4 py-2 hover:bg-gray-100"
            >
              Dummy 1
            </a>
          </li>
          <li>
            <a
              href="#"
              className="block px-4 py-2 hover:bg-gray-100"
            >
              Dummy 2
            </a>
          </li>
          <li>
            <a
              href="#"
              className="block px-4 py-2 hover:bg-gray-100"
            >
              Dummy 3
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
