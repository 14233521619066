import React from "react";

export const MyHeading = ({ newStyle, addStyle, children }) => {
  return (
    <div
      className={
        newStyle
          ? newStyle
          : "font-sans mx-2 my-2 text-2xl text-lime-950 " + addStyle
      }
    >
      {children}
    </div>
  );
};
