import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowUpDownLine } from "react-icons/ri";
import { MyStatus } from "../../Components/MyStatus";
import { MyDropdown } from "../../Components/MyDropdown";
import {
  setTransactions,
  transactionsSlice,
} from "../../store/transactionsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  clearExchangeRates,
  exchangeRateSlice,
  setExchangeRates,
} from "../../store/exchangeRateSlice";
const colums = [
  { label: "Transaction Date", key: "transaction_date", sortable: true },
  { label: "Invoice No.", key: "invoice_number", sortable: true },
  { label: "Payer", key: "payer", sortable: true },
  { label: "Payee", key: "payee", sortable: true },
  { label: "Amount", key: "amount", sortable: true },
  { label: "USD Equivalent", key: "amount", sortable: true },
  { label: "Status", key: "status", sortable: true },
  { label: "Action", key: "action", sortable: false },
];

export const TransactionsTable = () => {
  const transactions = useSelector((state) => state[transactionsSlice.name]);
  const exchangeRate = useSelector((state) => state[exchangeRateSlice.name]);
  const dispatch = useDispatch();
  const setTransactionsData = (data) => dispatch(setTransactions(data));

  const [sorting, setSorting] = useState({
    key: "invoice_number",
    ascending: false,
  });
  function applySorting(key) {
    setSorting((sorting) => {
      return { key: key, ascending: !sorting.ascending };
    });
  }
  async function fetchCurrentExchangeRate() {
    if (
      !exchangeRate.date ||
      moment(exchangeRate.date).format("YYYY-MM-DD") !=
        moment().format("YYYY-MM-DD") // the api to fetch exchange rates has limit of 1000 api calls per month so I had restricted that to call api only one time a day. So it will give true only once a day
    ) {
      console.log("fetching!!");
      const response = await fetch(
        "https://openexchangerates.org/api/latest.json?app_id=12fe5419135b4b13943b557b5c32a3fb"
      );
      const data = await response.json();
      if (data.success) {
        dispatch(
          setExchangeRates({
            date: moment(),
            INR_to_USD: data.rates.INR,
          })
        );
      } else {
        dispatch(setExchangeRates({ date: moment(), INR_to_USD: 83 })); //as its a free api I may ran out of free tier which 1000 call permonth so incase if it fails added a static rate.
      }
    } else {
      console.log("Exchange Rates for today is already fetched!!");
      // dispatch(clearExchangeRates())
    }
  }
  useEffect(() => {
    fetchCurrentExchangeRate();
  }, []);


  useEffect(() => {
    const currentTransactions = [...transactions];
    const sortedCurrentUsers = currentTransactions.sort((a, b) => {
      if (sorting.key == "transaction_date") {
        return moment(a[sorting.key]).format("YYYY-MM-DD") <
          moment(b[sorting.key]).format("YYYY-MM-DD")
          ? -1
          : 1;
      } else if (typeof a[sorting.key] == "number") {
        return a[sorting.key] - b[sorting.key];
      }
      return a[sorting.key]?.localeCompare(b[sorting.key]);
    });
    setTransactionsData(
      sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse()
    );
  }, [sorting]);
  return (
    <div className="m-5 relative overflow-x-auto  shadow-md sm:rounded-lg scroll-ms-48">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {colums.map((column, index) => (
              <th scope="col" className="px-6 py-3" key={index}>
                <div className="flex items-center">
                  {column.label}
                  {column.sortable && (
                    <a
                      href="#"
                      onClick={() => {
                        applySorting(column.key);
                      }}
                    >
                      <RiArrowUpDownLine className="w-3 h-3 ml-1.5" />
                    </a>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr
              className="bg-white border-b hover:bg-gray-50"
              key={index}
            >
              <td className="px-6 py-4">
                {moment(transaction?.transaction_date).format("DD/MM/YYYY")}
              </td>
              <td className="px-6 py-4">
                <a
                  href={`/transaction_details/${transaction.uniqueId}`}
                  className="text-blue-600 hover:underline font-sans font-medium"
                >
                  {transaction.invoice_number}
                </a>
              </td>
              <td className="px-6 py-4">
                <div className="flex flex-row items-center">
                  <img className=" h-4 me-1" src={transaction.payer_flag} />
                  <div>{transaction.payer}</div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="flex flex-row items-center">
                  <img className=" h-4 me-1" src={transaction.payee_flag} />
                  <div>{transaction.payee}</div>
                </div>
              </td>
              <td className="px-6 py-4">
                ₹{" "}
                {transaction.amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td className="px-6 py-4">
                ${" "}
                {(transaction.amount / exchangeRate.INR_to_USD)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td className="px-6 py-4">
                <MyStatus status={transaction.status} />
              </td>
              <td className="px-6 py-4">
                <MyDropdown uniqueId={index} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
