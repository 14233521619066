import { createSlice } from "@reduxjs/toolkit";
import { mockTransactions } from "../screens/Dashboard/mockTransactions";
const initialState = mockTransactions; //setting intial transactions as right now I cannot fetch them through api

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialState,
  reducers: {
    // Actions to set the transactions
    setTransactions(state, { payload }) {
      state = [...payload];
      return state;
    },
    saveTransactionChanges(state, { payload }) {
      let index = state.findIndex(
        (transaction) => transaction.uniqueId == payload.uniqueId
      );
      if (index != -1) {
        state[index] = { ...state[index], ...payload };
      }

      return state;
    },
    clearTransactions() {
      return initialState;
    },
  },
});

export const { setTransactions, saveTransactionChanges, clearTransactions } =
  transactionsSlice.actions;
