import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { transactionDetailsSlice } from "./transactionDetailsSlice";
import { transactionsSlice } from "./transactionsSlice";
import { exchangeRateSlice } from "./exchangeRateSlice";
const persistConfig = {
  key: "root",
  storage,
};
const reducers = combineReducers({
  [transactionDetailsSlice.name]: transactionDetailsSlice.reducer,
  [transactionsSlice.name]: transactionsSlice.reducer,
  [exchangeRateSlice.name]: exchangeRateSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export default store;
export const persistor = persistStore(store);
