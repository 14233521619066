import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import { TransactionDetails } from "./screens/TransactionDetails";
import { Toaster } from "react-hot-toast";
import store from "./store/store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routes>
          <Route path="*" element={<Dashboard />} />
          <Route path="/transaction_details/:uniqueId" element={<TransactionDetails />} />
        </Routes>
      </Provider>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default App;
