import React from "react";

export const MyCard = ({
  heading,
  subtitle,
  icon,
  bgColor = "bg-slate-200",
  iconBgColor = "bg-slate-100",
  textColor="text-indigo-950",
}) => {
  return (
    <div
      className={`flex items-center w-full h-40 rounded-3xl lg:p-10 p-4 mb-4 ${bgColor}`}
    >
      {icon && (
        <div className={`flex w-10 h-10 ${iconBgColor} items-center justify-center rounded-md`}>
          {icon()}
        </div>
      )}
      <div className="flex flex-col items-start ml-3">
        {heading && (
          <div className={`font-sans mx-2 text-sm ${textColor}`}>
            {heading}
          </div>
        )}
        {subtitle && (
          <div className={`font-sans mx-2 lg:text-3xl font-bold ${textColor}`}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};
