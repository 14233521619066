import React from "react";

export const MyDropdownInput = ({
  label,
  data,
  value,
  onChange,
  required,
  placeholder,
  disabled
}) => {
  return (
    <div className="flex flex-col mx-5 mb-5 ">
      <div className="flex flex-row ">
        <div className="font-sans  text-md text-lime-800 ">{label}</div>
        <div className="font-sans  text-md text-red-500 ">
          {required ? "*" : ""}
        </div>
      </div>
      <select
        disabled={disabled}
        onChange={(e) => {
          let selectedValue = e.target.value;
          onChange(selectedValue);
        }}
        value={value}
        id="countries"
        className="rounded-lg  outline-none border-2 border-lime-500 focus:border-lime-600 hover:border-lime-600 p-2 text-lime-800"
      >
        <option value={""}>
          {placeholder ? placeholder : "Choose an option"}
        </option>
        {data.map((item, index) => (
          <option key={index} value={item.id}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};
