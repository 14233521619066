import React, { useEffect } from "react";
import { MyContainer } from "../../Components/MyContainer";
import { FaExchangeAlt, FaRupeeSign, FaCheck } from "react-icons/fa";
import { MyCard } from "../../Components/MyCard";
import { TransactionsTable } from "./TransactionsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  setTransactions,
  transactionsSlice,
} from "../../store/transactionsSlice";
import { mockTransactions } from "./mockTransactions";

export const Dashboard = () => {
  const transactions = useSelector((state) => state[transactionsSlice.name]);
  const dispatch = useDispatch();
  useEffect(() => {
    // getTransactions();
  }, []);
  const getTransactions = () => {
    //here we will fetch the data asynchronously when we will fetch it from api
    //right now setting data from a moch data set
    // dispatch(setTransactions(mockTransactions)); // commented out because it will change the edits again to initial state as we are not saving and fetching data from api.
  };

  return (
    <MyContainer>
      <div className="flex flex-row min-w-full pt-4 px-9  h-min">
        <div className="min-w-full h-min lg:columns-3">
          <MyCard
            bgColor="bg-indigo-200"
            iconBgColor="bg-indigo-100"
            textColor="text-indigo-900"
            heading={"Transactions"}
            subtitle={transactions.length}
            icon={() => <FaExchangeAlt className="text-indigo-500 " />}
          />
          <MyCard
            heading={"Total Amount"}
            subtitle={`₹ ${transactions
              .reduce(
                (total, transaction) => total + Number(transaction.amount),
                0
              )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            icon={() => <FaRupeeSign className="text-slate-500 " />}
          />
          <MyCard
            heading={"Third State Transactions"}
            subtitle={
              transactions.filter((transaction) => transaction.status >= 3)
                .length
            }
            icon={() => <FaCheck className="text-slate-500 " />}
          />
        </div>
      </div>
      <TransactionsTable />
    </MyContainer>
  );
};
