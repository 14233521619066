import moment from "moment";

export const mockTransactions = [
  {
    uniqueId: 1,
    transaction_date: moment().day(1),
    invoice_number: "123456",
    payer: "Cyan Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags680/India.jpg",
    payee: "Zero Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags680/United_States.jpg",

    amount: 345500000,
    status: 1,
  },
  {
    uniqueId: 2,
    transaction_date: moment().day(1),
    invoice_number: "243211",
    payer: "Orange Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Afghanistan.jpg",
    payee: "One Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Albania.jpg",
    amount: 234500000,
    status: 2,
  },
  {
    uniqueId: 3,
    transaction_date: moment().day(2),
    invoice_number: "4212",
    payer: "Green Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Argentina.jpg",
    payee: "Two Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Costa_Rica.jpg",
    amount: 1223500000,
    status: 3,
  },
  {
    uniqueId: 4,
    transaction_date: moment().day(3),
    invoice_number: "12421",
    payer: "Red Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Egypt.jpg",
    payee: "Three Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Ghana.jpg",
    amount: 5442200000,
    status: 2,
  },
  {
    uniqueId: 5,
    transaction_date: moment().day(4),
    invoice_number: "4124124",
    payer: "Yellow Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Hong_Kong.jpg",
    payee: "Four Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Israel.jpg",
    amount: 2345600000,
    status: 3,
  },
  {
    uniqueId: 6,
    transaction_date: moment().day(5),
    invoice_number: "12414",
    payer: "White Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Liechtenstein.jpg",
    payee: "Five Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Lithuania.jpg",
    amount: 9543200000,
    status: 1,
  },
  {
    uniqueId: 7,
    transaction_date: moment().day(1),
    invoice_number: "124124",
    payer: "Black Limited",
    payer_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Myanmar.jpg",
    payee: "Six Limited",
    payee_flag:
      "https://www.sciencekids.co.nz/images/pictures/flags96/Russia.jpg",
    amount: 5678900000,
    status: 1,
  },
];
