import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

export const transactionDetailsSlice = createSlice({
  name: "transactionDetails",
  initialState: initialState,
  reducers: {
    // Action to set the authentication status
    setTransactionDetails(state, { payload }) {
      state = { ...payload };
      return state;
    },
    setFormValue(state, { payload }) {
      state = { ...state, ...payload };
      return state;
    },
    clearTransactionDetails() {
      return initialState;
    },
  },
});
export const { setTransactionDetails, setFormValue, clearTransactionDetails } =
  transactionDetailsSlice.actions;
