import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  saveTransactionChanges,
  transactionsSlice,
} from "../store/transactionsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormValue,
  setTransactionDetails,
  transactionDetailsSlice,
} from "../store/transactionDetailsSlice";
import { MyContainer } from "../Components/MyContainer";
import { MyHeading } from "../Components/MyHeading";
import { MyTextInput } from "../Components/MyTextInput";
import { FaRegEdit, FaChevronLeft } from "react-icons/fa";
import { MyDropdown } from "../Components/MyDropdown";
import { StatusList } from "../helper/constants";
import { MyDropdownInput } from "../Components/MyDropdownInput";
import moment from "moment";
import { toast } from "react-hot-toast";

export const TransactionDetails = () => {
  const navigate = useNavigate();
  const transactions = useSelector((state) => state[transactionsSlice.name]);
  const transactionDetails = useSelector(
    (state) => state[transactionDetailsSlice.name]
  );
  const dispatch = useDispatch();
  const setForm = (val) => dispatch(setFormValue(val));
  const { uniqueId } = useParams();
  const [editing, setediting] = useState(false);
  useEffect(() => {
    if (uniqueId) {
      getTransactionDetails(uniqueId);
    }
  }, [uniqueId]);
  const getTransactionDetails = (id) => {
    // Normally we will do the asyc api call to fetch details but we are using mock data so I will find the transaction data from list.
    let transaction = transactions.find(
      (transaction) => transaction.uniqueId == id
    );
    if (transaction) {
      dispatch(setTransactionDetails(transaction));
    }
  };
  const onClickEdit = () => {
    setediting((editing) => !editing);
  };
  const onClickSave = () => {
    setediting((editing) => !editing);
    dispatch(saveTransactionChanges(transactionDetails));
    toast.success("Successfully saved the transaction!");
  };

  return (
    <MyContainer>
      <div className="flex flex-row min-w-full min-h-screen justify-center border-r-8 bg-indigo-50  py-9">
        <div className=" flex-col bg-indigo-200 rounded-2xl shadow-2xl ">
          <div className="flex pt-4 ps-4">
            <button
              className="min-w-min items-center p-2 text-sm font-medium text-center text-gray-900 bg-gray-200 rounded-full hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
              type={"button"}
              onClick={() => navigate("/")}
            >
              <FaChevronLeft />
            </button>
          </div>
          <div className="flex flex-row p-2">
            <MyHeading>Transaction Details</MyHeading>
            <div className=" w-20">
              {!editing && (
                <button
                  onClick={onClickEdit}
                  type="button"
                  className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  <FaRegEdit className=" text-center" />
                </button>
              )}
            </div>
          </div>
          <MyTextInput
            label="Transaction Date"
            value={moment(transactionDetails?.transaction_date).format(
              "YYYY-MM-DD"
            )}
            onChange={(val) => {
              dispatch(setForm({ transaction_date: moment(val) }));
            }}
            date
            disabled={!editing}
            required
          />
          <MyTextInput
            label="Inovice Number"
            value={transactionDetails?.invoice_number}
            onChange={(val) => {
              dispatch(setForm({ invoice_number: val }));
            }}
            numeric
            disabled={!editing}
            required
          />
          <MyTextInput
            label="Payer"
            value={transactionDetails?.payer}
            onChange={(val) => {
              dispatch(setForm({ payer: val }));
            }}
            disabled={!editing}
            required
          />
          <MyTextInput
            label="Payee"
            value={transactionDetails?.payee}
            onChange={(val) => {
              dispatch(setForm({ payee: val }));
            }}
            disabled={!editing}
            required
          />
          <MyTextInput
            label="Amount"
            value={transactionDetails?.amount}
            onChange={(val) => {
              dispatch(setForm({ amount: val }));
            }}
            numeric
            disabled={!editing}
            required
          />
          <MyDropdownInput
            label="Status"
            placeholder="Select Your Array Type"
            data={StatusList}
            value={transactionDetails?.status}
            onChange={(val) => {
              setForm({ status: val });
            }}
            disabled={!editing}
            required
          />
          {editing && (
            <button
              onClick={onClickSave}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mx-2 mb-2"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </MyContainer>
  );
};
