import React from "react";

export const MyStatus = ({ status }) => {
  const statuses = [
    { code: 1, label: "First" },
    { code: 2, label: "Second" },
    { code: 3, label: "Third" },
  ];
  return (
    <div className="flex flex-row items-start">
      {statuses.map((thisStatus, index) => (
        <div className=" mx-2 items-cente" key={index}>
          <div
            className={`h-1 w-10 rounded-md ${
              status >= thisStatus.code ? "bg-indigo-900" : "bg-indigo-200"
            }`}
          ></div>
          {status >= thisStatus.code && (
            <div className="items-center text-center text-[10px] font-sans font-bold text-black">
              {thisStatus.label}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
